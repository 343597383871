import { useCallback, useEffect, useRef, useState } from 'react';

import crossfilter from 'crossfilter2';
import * as d3 from 'd3';
import * as dc from 'dc';

import { createGenerateClassName, StylesProvider } from '@mui/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { SelectChangeEvent } from '@mui/material';
import Dashboard, { IDashboard } from 'components/Dashboard/Dashboard';
import DashboardBacktest, { IDashboarBacktest } from 'components/Dashboard/DashboardBacktest';
import { isSameDay, parse, startOfDay, subDays } from 'date-fns';
import {
  BDDFCharts,
  BPFCharts,
  DigitalClubsCharts,
  HBBDCharts,
  MFAAASV2Charts,
  MFAAASCharts,
} from 'utils/config/dashboardsConfs';
import {
  BDDFBacktestCharts,
  BNPPAMBacktestCharts,
  BPFBacktestCharts,
  DigitalClubsBacktestCharts,
  HBBEBacktestCharts,
  HBFRBacktestCharts,
  BCEFBacktestCharts,
  BNLBacktestCharts,
} from 'utils/config/dashboardsBacktestConfs';
import { 
  getLogs, 
  getLogsBacktest, 
  getLogsExport, 
  getLogsExportBacktest, 
  whoAmI 
} from './utils/api';
import { isBetweenTime } from './utils/dateUtils';
import Kpi from './utils/Kpi';
import { 
  DistribId,
  DistribIdBacktest,
  Cf, 
  IDashboardProps, 
  IDashboardBacktestProps, 
  isLegacyLog, 
  Kpis, 
  Log, 
  User 
} from './utils/types';

import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import MainBar from './components/MainBar/MainBar';
import SignInDialog from './components/SignInDialog/SignInDialog';

import 'dc/dist/style/dc.css';
import 'react-virtualized/styles.css';

import './App.css';
import { weightTolerance } from './utils/constants';
import { getLogInvestmentAmount } from './utils/numbersUtils';

const generateClassName = createGenerateClassName();

const theme = createTheme({
  palette: {
    primary: {
      main: '#00965E',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

dc.config.defaultColors([...d3.schemeCategory10]);

function App() {
  const [beginDate, setBeginDate] = useState(subDays(startOfDay(new Date()), 7));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));
  const [kpis, setKpis] = useState<Kpis>({
    avgDuration: new Kpi(),
    maxDuration: new Kpi(),
    durationUnder2s: new Kpi(),
    durationUnder5s: new Kpi(),

    avgFinalVol: new Kpi(),
    avgInitialVol: new Kpi(),

    avgFinalRisk: new Kpi(),
    avgInitialRisk: new Kpi(),

    avgFinalESGWeight: new Kpi(),
    avgInitialESGWeight: new Kpi(),

    avgFinalTrackingError: new Kpi(),
    avgInitialTrackingError: new Kpi(),

    avgInvestmentAmount: new Kpi(),
    maxInvestmentAmount: new Kpi(),
    avgPortfolioAmount: new Kpi(),
    maxPortfolioAmount: new Kpi(),

    avgInitialEuroFundWeight: new Kpi(),
    avgInitialReimUCWeight: new Kpi(),
    avgInitialUCWeight: new Kpi(),

    avgFinalEuroFundWeight: new Kpi(),
    avgFinalReimUCWeight: new Kpi(),
    avgFinalUCWeight: new Kpi(),

    maxFundsDuringRebalancing: new Kpi(),
    avgFundsDuringRebalancing: new Kpi(),
  });
  const [cf, setCf] = useState<Cf | null>(null);
  const [distribId, setDistribId] = useState<DistribId>();
  const [distribIdBacktest, setDistribIdBacktest] = useState<DistribIdBacktest>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isTeamExcluded, setIsTeamExcluded] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [logs, setLogs] = useState<Log[] | null>(null);
  const [workers, setWorkers] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const [rebalancingBeginTime, setRebalancingBeginTime] = useState(
    parse('16:00', 'HH:mm', new Date()),
  );
  const [filteredLogs, setFilteredLogs] = useState<Log[]>([]);

  const [rebalancingEndTime, setRebalancingEndTime] = useState(parse('23:00', 'HH:mm', new Date()));
  const [withRebalancing, setWithRebalancing] = useState(false);

  const dashboardRef = useRef<IDashboard>(null);
  const dashboardBacktestRef = useRef<IDashboarBacktest>(null);

  const clearError = () => setErrorMessage(null);

  const handleSignIn = (token: string) => {
    startWorking();

    localStorage.setItem('token', token);
    whoAmI()
      .then((userResponse) => signIn(userResponse))
      .catch(setErrorMessage)
      .finally(() => {
        stopWorking();
      });
  };

  const handleDitribIdsChange = (event: SelectChangeEvent<DistribId>) => {
    startWorking();

    setCf(null);
    setLogs(null);

    let newDistribId: DistribId | undefined = event.target.value as DistribId;

    setDistribIdBacktest(undefined);
    setDistribId(newDistribId);

    if(newDistribId === DistribId.MFAAAS_V2) {
      newDistribId = undefined;
    }

    getLogs(beginDate, endDate, newDistribId)
      .then((data: Log[]) => {
        if (data) {
          setLogs(data);
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK')
          setErrorMessage('Failed to get logs data, too many data.');
        else setErrorMessage('Failed to get logs data.');
      })
      .finally(() => {
        stopWorking();
      });
  };

  const handleDistribIdsBacktestChange = (event: SelectChangeEvent<DistribIdBacktest>) => {
    startWorking();

    setCf(null);
    setLogs(null);

    const newDistribId: DistribIdBacktest = event.target.value as DistribIdBacktest;

    setDistribId(undefined);
    setDistribIdBacktest(newDistribId);

    getLogsBacktest(beginDate, endDate, newDistribId)
      .then((data: Log[]) => {
        if (data) {
          setLogs(data);
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK')
          setErrorMessage('Failed to get logs data, too many data.');
        else setErrorMessage('Failed to get logs data.');
      })
      .finally(() => {
        stopWorking();
      });
  };

  const signIn = (newUser: User) => {
    if (newUser.role !== 'ADMIN') {
      setErrorMessage('You must be Admin to be able to use Smart Logs!');
      return;
    }

    setSignedIn(true);
    setUser(newUser);
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');

    setSignedIn(false);
    setCf(null);
    setErrorMessage(null);
    setLogs([]);
    setUser(null);
  };

  const startWorking = () => {
    document.body.classList.add('App-busy');
    setWorkers((prevState) => prevState + 1);
  };

  const stopWorking = () => {
    setWorkers((prevState) => prevState - 1);
    document.body.classList.remove('App-busy');
  };

  const fetchData = (inputBeginDate: Date, inputEndDate: Date, inputDistribId: DistribId) => {
    startWorking();

    let distribIdAux: DistribId | undefined = inputDistribId;

    if(inputDistribId === DistribId.MFAAAS_V2) {
      distribIdAux = undefined;
    }

    getLogs(inputBeginDate, inputEndDate, distribIdAux)
      .then((data) => {
        if (data) {
          setLogs(data);
        }
        setBeginDate(inputBeginDate);
        setEndDate(inputEndDate);
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK')
          setErrorMessage('Failed to get logs data, too many data.');
        else setErrorMessage('Failed to get logs data.');
      })
      .finally(() => {
        stopWorking();
      });
  };

  const fetchDataBacktest = (inputBeginDate: Date, inputEndDate: Date, inputDistribId: DistribIdBacktest) => {
    startWorking();

    getLogsBacktest(inputBeginDate, inputEndDate, inputDistribId)
      .then((data) => {
        if (data) {
          setLogs(data);
        }
        setBeginDate(inputBeginDate);
        setEndDate(inputEndDate);
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK')
          setErrorMessage('Failed to get logs data, too many data.');
        else setErrorMessage('Failed to get logs data.');
      })
      .finally(() => {
        stopWorking();
      });
  };

  const exportData = (inputBeginDate: Date, inputEndDate: Date, inputDistribId: DistribId) => {
    startWorking();

    let distribIdAux: DistribId | undefined = inputDistribId;

    if(inputDistribId === DistribId.MFAAAS_V2) {
      distribIdAux = undefined;
    }

    getLogsExport(inputBeginDate, inputEndDate, distribIdAux)
      .catch((error) => {
        if (error.code === 'ERR_NETWORK')
          setErrorMessage('Failed to get logs export file, too many data.');
        else setErrorMessage('Failed to get logs export file.');
      })
      .finally(() => {
        stopWorking();
      });
  };

  const exportDataBacktest = (inputBeginDate: Date, inputEndDate: Date, inputDistribId: DistribIdBacktest) => {
    startWorking();

    getLogsExportBacktest(inputBeginDate, inputEndDate, inputDistribId)
      .catch((error) => {
        if (error.code === 'ERR_NETWORK')
          setErrorMessage('Failed to get logs export file, too many data.');
        else setErrorMessage('Failed to get logs export file.');
      })
      .finally(() => {
        stopWorking();
      });
  };

  const updateKpis = useCallback(
    (inputLogs: Log[], inputKpis: Kpis) => {
      Object.keys(inputKpis).forEach((key) => inputKpis[key as keyof typeof inputKpis].clear());

      if (inputLogs.length > 0) {
        inputLogs.reduce((currentKpis, log) => {
          let initialPtfAmout = 1;
          let finalPtfAmount = 1;
          if (isLegacyLog(log)) {
            initialPtfAmout = log.portfolio_amount ?? 1;
            finalPtfAmount = initialPtfAmout + getLogInvestmentAmount(log);
            currentKpis.avgInitialVol.weightedSumOf(log.initial_volatility, initialPtfAmout);
            currentKpis.avgFinalVol.weightedSumOf(log.final_volatility, finalPtfAmount);
            currentKpis.avgInitialRisk.weightedSumOf(log.risk_before_operation, initialPtfAmout);
            currentKpis.avgFinalRisk.weightedSumOf(log.risk_after_operation, finalPtfAmount);
            currentKpis.avgInvestmentAmount.sumOf(log.investment_amount);
            currentKpis.maxInvestmentAmount.maxOf(log.investment_amount);
            currentKpis.avgPortfolioAmount.sumOf(log.portfolio_amount);
            currentKpis.maxPortfolioAmount.maxOf(log.portfolio_amount);
          } else {
            currentKpis.avgInitialVol.weightedSumOf(log.initial_volatility, initialPtfAmout);
            currentKpis.avgFinalVol.weightedSumOf(log.final_volatility, finalPtfAmount);
            currentKpis.avgInitialESGWeight.sumOf(log.initial_esg_weight);
            currentKpis.avgFinalESGWeight.sumOf(log.final_esg_weight);
            currentKpis.avgInitialTrackingError.sumOf(log.initial_tracking_error);
            currentKpis.avgFinalTrackingError.sumOf(log.final_tracking_error);
          }

          const compoFinal =
            Math.abs(
              (log.composition_overview.euro_fund_weight ?? 0) +
                (log.composition_overview.reim_uc_weight ?? 0) +
                (log.composition_overview.uc_weight ?? 0) -
                100,
            ) < weightTolerance;

          if (compoFinal) {
            currentKpis.avgInitialEuroFundWeight.weightedSumOf(
              log.initial_portfolio_overview.euro_fund_weight,
              initialPtfAmout,
            );
            currentKpis.avgInitialReimUCWeight.weightedSumOf(
              log.initial_portfolio_overview.reim_uc_weight,
              initialPtfAmout,
            );
            currentKpis.avgInitialUCWeight.weightedSumOf(
              log.initial_portfolio_overview.uc_weight,
              initialPtfAmout,
            );

            currentKpis.avgFinalEuroFundWeight.weightedSumOf(
              log.composition_overview.euro_fund_weight,
              finalPtfAmount,
            );
            currentKpis.avgFinalReimUCWeight.weightedSumOf(
              log.composition_overview.reim_uc_weight,
              finalPtfAmount,
            );
          }

          currentKpis.durationUnder2s.sumOf(
            log.duration_in_ms && log.duration_in_ms < 2000 ? 100 : 0,
          );
          currentKpis.durationUnder5s.sumOf(
            log.duration_in_ms && log.duration_in_ms < 5000 ? 100 : 0,
          );

          currentKpis.avgDuration.sumOf(log.duration_in_ms);
          currentKpis.maxDuration.maxOf(log.duration_in_ms);

          currentKpis.avgFinalUCWeight.weightedSumOf(
            log.composition_overview.uc_weight,
            finalPtfAmount,
          );

          if (
            isBetweenTime(log.date, rebalancingBeginTime, rebalancingEndTime) &&
            log.http_route?.includes('arbitrage')
          ) {
            currentKpis.avgFundsDuringRebalancing.sumOf(log.funds_in_portfolio ?? 0);
            currentKpis.maxFundsDuringRebalancing.maxOf(log.funds_in_portfolio ?? 0);
          }

          return currentKpis;
        }, inputKpis);

        inputKpis.avgDuration.avg();
        inputKpis.durationUnder2s.avg();
        inputKpis.durationUnder5s.avg();

        inputKpis.avgFinalVol.weightedAvg();
        inputKpis.avgInitialVol.weightedAvg();
        inputKpis.avgFinalRisk.weightedAvg();
        inputKpis.avgInitialRisk.weightedAvg();
        inputKpis.avgInvestmentAmount.avg();
        inputKpis.avgPortfolioAmount.avg();

        inputKpis.avgInitialEuroFundWeight.weightedAvg();
        inputKpis.avgInitialReimUCWeight.weightedAvg();
        inputKpis.avgInitialUCWeight.weightedAvg();

        inputKpis.avgFinalEuroFundWeight.weightedAvg();
        inputKpis.avgFinalReimUCWeight.weightedAvg();
        inputKpis.avgFinalUCWeight.weightedAvg();

        inputKpis.avgFundsDuringRebalancing.avg();
      }

      return inputKpis;
    },
    [rebalancingBeginTime, rebalancingEndTime],
  );

  useEffect(() => {  
    dc.deregisterAllCharts();

    if (logs) {
      const ndx = crossfilter<Log>(logs);
      const all = ndx.groupAll<number>();

      setFilteredLogs(ndx.allFiltered());

      const isFromSATeamDimension = ndx.dimension(
        (dimensionLog) => dimensionLog.is_from_smart_allocation_team ?? false,
      );

      const rebalancingGroup = ndx
        .dimension(
          (dimensionLog) =>
            isBetweenTime(dimensionLog.date, rebalancingBeginTime, rebalancingEndTime) &&
            !!dimensionLog.http_route?.includes('arbitrage'),
        )
        .group();

      const userDimension = ndx.dimension((log) => log.user_id ?? 0);
      const userGroup = userDimension.group();

      setCf((prevState) => {
        if (prevState) {
          if (prevState.all) {
            prevState.all.dispose();
          }
          if (prevState.durationDimension) {
            prevState.durationDimension.dispose();
          }
          if (prevState.investmentAmountDimension) {
            prevState.investmentAmountDimension.dispose();
          }
          if (prevState.portfolioAmountDimension) {
            prevState.portfolioAmountDimension.dispose();
          }
          if (prevState.userDimension) {
            prevState.userDimension.dispose();
          }
          if (prevState.userGroup) {
            prevState.userGroup.dispose();
          }
          if (prevState.rebalancingGroup) {
            prevState.rebalancingGroup.dispose();
          }
        }
        return {
          dc,
          all,
          durationDimension: ndx.dimension((log) => log.duration_in_ms ?? 0),
          investmentAmountDimension: ndx.dimension((log) =>
            isLegacyLog(log) ? log.investment_amount ?? 0 : 0,
          ),
          ndx,
          portfolioAmountDimension: ndx.dimension((log) =>
            isLegacyLog(log) ? log.portfolio_amount ?? 0 : 0,
          ),
          isFromSATeamDimension,
          userDimension,
          userGroup,
          rebalancingGroup,
        };
      });

      if(distribId) {
        setKpis((prevState) => {
          if (prevState) {
            prevState.maxDuration.reset();
            prevState.maxInvestmentAmount.reset();
            prevState.maxPortfolioAmount.reset();
          }
          return updateKpis(ndx.allFiltered(), prevState);
        });
  
        setWithRebalancing(
          !!distribId &&
            isSameDay(endDate, beginDate) &&
            [DistribId.MFAAAS_V2, DistribId.MFAAAS, DistribId.BPF].includes(distribId),
        );
      }
    }
  }, [beginDate, distribId, distribIdBacktest, endDate, logs, rebalancingBeginTime, rebalancingEndTime, updateKpis]);

  const toggleExcludeTeam = () => {
    /**
     * Here a callback is used to ensure that the filter is enabled once
     * the asynchronous setState method has indeed changed the state
     * See https://reactjs.org/docs/react-component.html#setstate for reference
     */
    setIsTeamExcluded((prevState) => !prevState);
  };

  useEffect(() => {
    if (distribIdBacktest) {
      return;
    }
  
    if (cf) {
      const dimension = cf.isFromSATeamDimension;

      if (isTeamExcluded) {
        dimension.filterFunction((d) => !d);
      } else {
        dimension.filterAll();
      }

      if (dashboardRef.current) {
        dashboardRef.current.updateAllCharts();
      }
    }
  }, [distribIdBacktest, cf, isTeamExcluded]);

  const updateCharts = useCallback(() => {
    if (cf) {
      const newFilteredLogs = cf.ndx.allFiltered();
      setFilteredLogs(newFilteredLogs);
      setKpis((prevState) => updateKpis(newFilteredLogs, prevState));
    }
  }, [cf, updateKpis]);

  const updateChartsBacktest = useCallback(() => {
    if (cf) {
      const newFilteredLogs = cf.ndx.allFiltered();
      setFilteredLogs(newFilteredLogs);
    }
  }, [cf]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      startWorking();
      whoAmI()
        .then((data) => {
          if (data) {
            signIn(data);
          }
        })
        .finally(() => {
          stopWorking();
        });
    }
  }, []);

  const renderDashboard = () => {
    if(distribIdBacktest) {
      const getChartsToDisplay = () => {
        switch (distribIdBacktest) {
          case 'bddf':
            return BDDFBacktestCharts;
          case 'bnpp_am':
            return BNPPAMBacktestCharts;
          case 'bpf':
            return BPFBacktestCharts;
          case 'digital-clubs':
            return DigitalClubsBacktestCharts;
          case 'hb_be':
            return HBBEBacktestCharts;
          case 'hb_fr':
            return HBFRBacktestCharts;
          case 'bcef':
            return BCEFBacktestCharts;
          case 'bnl':
            return BNLBacktestCharts;
          default:
            return BDDFBacktestCharts;
        }
      };

      const props: IDashboardBacktestProps = {
        beginDate,
        endDate,
        cf,
        filteredLogs,
        distribId: distribIdBacktest,
        exportData: exportDataBacktest,
        fetchData: fetchDataBacktest,
        startWorking,
        stopWorking,
        updateCharts: updateChartsBacktest,
        workers,
        setErrorMessage,
        rebalancingBeginTime,
        rebalancingEndTime,
        setRebalancingBeginTime,
        setRebalancingEndTime,
        withRebalancing,
        chartsToDisplay: getChartsToDisplay(),
      };

      return <DashboardBacktest {...props} ref={dashboardBacktestRef} />;
    }

    if (distribId) {
      const getChartsToDisplay = () => {
        switch (distribId) {
          case 'bddf':
            return BDDFCharts;
          case 'bpf':
            return BPFCharts;
          case 'digital-clubs':
            return DigitalClubsCharts;
          case 'hb_be':
            return HBBDCharts;
          case 'mfaaas_v2':
            return MFAAASV2Charts;
          default:
            return MFAAASCharts;
        }
      };

      const props: IDashboardProps = {
        beginDate,
        endDate,
        cf,
        filteredLogs,
        distribId,
        exportData,
        fetchData,
        kpis,
        startWorking,
        stopWorking,
        updateCharts,
        workers,
        setErrorMessage,
        rebalancingBeginTime,
        rebalancingEndTime,
        setRebalancingBeginTime,
        setRebalancingEndTime,
        withRebalancing,
        chartsToDisplay: getChartsToDisplay(),
      };

      return <Dashboard {...props} ref={dashboardRef} />;
    }

    return null;
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <MainBar
          cf={cf}
          changeDistribIds={handleDitribIdsChange}
          changeDistribIdsBacktest={handleDistribIdsBacktestChange}
          isTeamExcluded={isTeamExcluded}
          handleSignOut={handleSignOut}
          signedIn={signedIn}
          toggleExcludeTeam={toggleExcludeTeam}
          user={user}
          workers={workers}
          distribId={distribId}
          distribIdBacktest={distribIdBacktest}
          withRebalancingCount={withRebalancing}
        />
        {(distribId || distribIdBacktest) && renderDashboard()}
        {!signedIn && workers === 0 && <SignInDialog handleSignIn={handleSignIn} />}
        <ErrorDialog message={errorMessage} onErrorDisplay={clearError} />
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
