import { useEffect, useState } from 'react';

import {
  Column,
  SortDirection,
  Table,
  type RowMouseEventHandlerParams,
  type SortDirectionType,
} from 'react-virtualized';
import 'react-virtualized/styles.css';

import { type DistribIdBacktest, type Log, type RawLog } from 'utils/types';
import { getLog } from '../../utils/api';
import { logColumnsMappingBacktest } from '../../utils/constants';

import DetailsDialog from '../DetailsDialog/DetailsDialog';

import './LogTable.css';

export function sort(logs: Log[], sortBy: keyof Log, sortDirection: string) {
  logs.sort((log1, log2) => {
    let result = 0;

    const value1 = log1[sortBy];
    const value2 = log2[sortBy];

    if (!value1) return 1;
    if (!value2) return -1;

    if (value1 < value2) {
      result = -1;
    } else if (value1 > value2) {
      result = 1;
    }

    if (sortDirection === SortDirection.DESC) {
      return -result;
    }

    return result;
  });
}

interface ILogTableBacktestProps {
  logs: Log[];
  startWorking: () => void;
  stopWorking: () => void;
  onLogRequestError: (message: string) => void;
  distribId: DistribIdBacktest;
}

function LogTableBacktest({ ...props }: ILogTableBacktestProps) {
  const [sortBy, setSortBy] = useState<keyof Log>('start_date');
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(SortDirection.DESC);
  const [logs, setLogs] = useState<Log[]>([]);
  const [log, setLog] = useState<RawLog | null>(null);
  const [openDetails, setOpenDetails] = useState(false);

  const onHeaderClick = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
    let direction = info.sortDirection;
    if (info.sortBy === sortBy) {
      if (sortDirection === SortDirection.ASC) {
        direction = SortDirection.DESC;
      } else {
        direction = SortDirection.ASC;
      }
    }

    const newLogs = props.logs;

    sort(newLogs, info.sortBy as keyof Log, direction);

    setLogs(newLogs);
    setSortBy(info.sortBy as keyof Log);
    setSortDirection(direction);
  };

  const onRowClick = ({ rowData }: RowMouseEventHandlerParams) => {
    props.startWorking();

    getLog(rowData.log_id, true)
      .then((rawLog) => {
        setLog({ ...rawLog, http_route: rowData.endpoint, id: rowData.id });
        setOpenDetails(true);
      })
      .catch(() => {
        props.onLogRequestError(`Failed to get log ${rowData.id} data.`);
      })
      .finally(props.stopWorking);
  };

  const onDetailsClose = () => {
    setOpenDetails(false);
  };

  const getRowClassName = ({ index }: { index: number }) => {
    if (index < 0) {
      return 'Table-header';
    }
    const rowLog = logs[index];

    if (rowLog.status === 'Client Error') {
      return index % 2 === 0
        ? 'Table-even-row Table-client-error'
        : 'Table-odd-row Table-client-error';
    }
    if (rowLog.status === 'Server Error') {
      return index % 2 === 0
        ? 'Table-even-row Table-server-error'
        : 'Table-odd-row Table-server-error';
    }

    return index % 2 === 0 ? 'Table-even-row' : 'Table-odd-row';
  };

  useEffect(() => {
    setLogs(props.logs);
  }, [props.logs]);

  return (
    <div>
      <Table
        gridClassName="Table-grid"
        headerHeight={50}
        height={315}
        onRowClick={onRowClick}
        rowClassName={getRowClassName}
        rowCount={logs.length}
        rowGetter={({ index }) => logs[index]}
        rowHeight={20}
        sort={onHeaderClick}
        sortBy={sortBy}
        sortDirection={sortDirection}
        width={1500}
      >
        {logColumnsMappingBacktest.startDate[props.distribId] && (
          <Column dataKey="start_date" label="Date" width={140} />
        )}
        {logColumnsMappingBacktest.httpRoute[props.distribId] && (
          <Column dataKey="endpoint" label="HTTP Route" width={180} />
        )}
        {logColumnsMappingBacktest.httpStatus[props.distribId] && (
          <Column
            className="App-column-center"
            dataKey="http_status"
            label="HTTP Status"
            width={60}
          />
        )}
        {logColumnsMappingBacktest.duration[props.distribId] && (
          <Column
            cellDataGetter={({ rowData }) => rowData.readable_duration}
            className="App-column-right"
            dataKey="duration_in_ms"
            label="Duration"
            width={80}
          />
        )}
        {logColumnsMappingBacktest.errorType[props.distribId] && (
          <Column dataKey="error_type" label="Error Type" width={275} />
        )}
      </Table>
      {openDetails && log && (
        <DetailsDialog
          handleClose={onDetailsClose}
          log={log}
          startWorking={props.startWorking}
          stopWorking={props.stopWorking}
          setErrorMessage={props.onLogRequestError}
          isBacktest
        />
      )}
    </div>
  );
}

export default LogTableBacktest;
