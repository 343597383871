import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import GetApp from '@mui/icons-material/GetApp';
import Refresh from '@mui/icons-material/Refresh';

import { Button, TextField, styled } from '@mui/material';

import { chunk } from 'lodash';
import { IChart, getChart } from 'utils/chartsUtils';
import { format, parse, startOfDay } from 'date-fns';
import { IDashboardBacktestProps } from 'utils/types';
import LogTableBacktest from '../LogTable/LogTableBacktest';
import VolumeChart from '../charts/VolumeChart';


export interface IDashboarBacktest {
    updateAllCharts: () => void;
}

const DashboardBacktest = forwardRef<IDashboarBacktest, IDashboardBacktestProps>((
    {
      chartsToDisplay,
      beginDate,
      endDate,
      cf,
      filteredLogs,
      distribId,
      exportData,
      fetchData,
      startWorking,
      stopWorking,
      updateCharts,
      workers,
      setErrorMessage,
      rebalancingBeginTime,
      rebalancingEndTime,
      setRebalancingBeginTime,
      setRebalancingEndTime,
      withRebalancing,
    }: IDashboardBacktestProps,
    ref,
  ) => {
    const volumeChartRef = useRef<IChart>(null);
    const childrenRefs = useRef<{ [key: string]: IChart }>({});
    const [newBeginDate, setNewBeginDate] = useState(beginDate);
    const [newEndDate, setNewEndDate] = useState(endDate);

    const updateAllCharts = useCallback(() => {
        updateCharts();
        volumeChartRef.current?.update();
  
        chartsToDisplay.forEach((chart) => {
          if (chart.name !== 'empty') childrenRefs.current[chart.logKey].update();
        });
    }, [chartsToDisplay, updateCharts]);

    useImperativeHandle(
        ref,
        () => ({
          updateAllCharts,
        }),
        [updateAllCharts],
    );

    const getChartColumns = useCallback(() => {
        const columnChunks = chunk(chartsToDisplay, 2);
        return columnChunks.map((columnChunk) => (
          <div className="Column" key={columnChunk.map((chart) => chart.logKey).join('-')}>
            {columnChunk.map((chart) =>
              getChart({
                ...chart,
                cf: cf!,
                onFilter: updateAllCharts,
                ref: (chartRef: IChart) => {
                  childrenRefs.current[chart.logKey] = chartRef;
                },
              }),
            )}
          </div>
        ));
      }, [cf, chartsToDisplay, updateAllCharts]);

    return (
      cf &&
      distribId && (
        <div className="Column">
          <div className="Row">
            <VolumeChart
                beginDate={beginDate}
                cf={cf}
                endDate={endDate}
                onFilter={updateCharts}
                ref={volumeChartRef}
            />
            <div>
              <div className="Row">
                <TextField
                  defaultValue={format(newBeginDate, 'yyyy-MM-dd')}
                  disabled={workers > 0}
                  id="beginDate"
                  label="Begin Date"
                  onChange={(event) => setNewBeginDate(startOfDay(new Date(event.target.value)))}
                  required
                  type="date"
                  sx={{ marginLeft: '20px' }}
                  variant="standard"
                />
                <TextField
                  defaultValue={format(newEndDate, 'yyyy-MM-dd')}
                  disabled={workers > 0}
                  id="endDate"
                  label="End Date"
                  onChange={(event) => setNewEndDate(startOfDay(new Date(event.target.value)))}
                  required
                  type="date"
                  sx={{ marginLeft: '20px' }}
                  variant="standard"
                />
                <ChartAction
                  className="App-refresh"
                  disableRipple
                  onClick={() => {
                    fetchData(newBeginDate, newEndDate, distribId);
                  }}
                  title="Click to Refresh Data"
                  variant="contained"
                >
                  Refresh
                  <Refresh className="App-refresh-icon" />
                </ChartAction>
                <ChartAction
                  className="App-export"
                  disableRipple
                  onClick={() => exportData(beginDate, endDate, distribId)}
                  title="Click to export data on excel"
                  variant="contained"
                >
                  Export
                  <GetApp className="App-export-icon" />
                </ChartAction>
              </div>
              {withRebalancing && (
                <div className="Row" style={{ marginTop: '15px' }}>
                  <TextField
                    defaultValue={format(rebalancingBeginTime, 'HH:mm')}
                    disabled={workers > 0}
                    id="rebalancingBeginDate"
                    label="Rebalancing Begin Time"
                    onChange={(event) =>
                      setRebalancingBeginTime(parse(event.target.value, 'HH:mm', new Date()))
                    }
                    required
                    type="time"
                    sx={{ marginLeft: '20px', width: '25%' }}
                    variant="standard"
                  />
                  <TextField
                    defaultValue={format(rebalancingEndTime, 'HH:mm')}
                    disabled={workers > 0}
                    id="rebalancingEndDate"
                    label="Rebalancig End Time"
                    onChange={(event) =>
                      setRebalancingEndTime(parse(event.target.value, 'HH:mm', new Date()))
                    }
                    required
                    type="time"
                    sx={{ marginLeft: '20px', width: '25%' }}
                    variant="standard"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="Row" style={{ maxWidth: '100vw' }}>
            <LogTableBacktest
              logs={filteredLogs}
              startWorking={startWorking}
              stopWorking={stopWorking}
              onLogRequestError={(errorMessage: string) => setErrorMessage(errorMessage)}
              distribId={distribId}
            />
          </div>
          <div className="Row">{getChartColumns()}</div>
        </div>
      )
    );
  }
)

const ChartAction = styled(Button)`
  height: 36px;
  margin-left: 20px;
  margin-top: 10px;
  background-color: #ddd;
  color: black;

  &:hover {
    background-color: #00a76f;
    border-radius: 5px;
    color: white;
  }
`;

export default DashboardBacktest;
