import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Dialog, DialogContent, IconButton, styled } from '@mui/material';

import { GetApp } from '@mui/icons-material';
import { JSONTree } from 'react-json-tree';
import { RawLog } from 'utils/types';
import { getQrgInputs } from '../../utils/api';
import { DialogButton, DialogButtons, StyledDialogTitle } from '../ErrorDialog/ErrorDialog';
import './DetailsDialog.css';

const theme = {
  base00: '#dddddd', // background
  base01: '#383830',
  base02: '#49483e',
  base03: '#808080', // comments
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#808080',
  base08: '#000080', // null and undefined
  base09: '#0000FF', // boolean and number
  base0A: '#f4bf75',
  base0B: '#008000', // date and string
  base0C: '#a1efe4',
  base0D: '#660E7A', // JSON key
  base0E: '#ae81ff',
  base0F: '#cc6633',
  label: {
    fontWeight: 'bold',
  },
  nestedNodeItemString: {
    color: '#808080',
    fontStyle: 'italic',
  },
};

interface IDetailsDialogProps {
  startWorking: () => void;
  stopWorking: () => void;
  setErrorMessage: (message: string) => void;
  handleClose: () => void;
  isBacktest: boolean;
  log: RawLog;
}

function DetailsDialog({ ...props }: IDetailsDialogProps) {
  const getItemString = (type: any, data: any, itemType: any, itemString: string) => itemString;

  let inputJson = props.log?.input_request;
  let outputJson = props.log?.output_response;

  if (!props.isBacktest && inputJson && outputJson) {
    inputJson = JSON.parse(inputJson);
    outputJson = JSON.parse(outputJson);
  }

  const downloadInputs = () => {
    if (typeof inputJson==='object'){
      props.startWorking();
  
      getQrgInputs(inputJson, props.log.http_route)
        .catch(() => {
          props.setErrorMessage('Failed to get logs export file.');
        })
        .finally(() => {
          props.stopWorking();
        });
    }
  };


  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      style={{ width: '70%', margin: '0 15%' }}
      onClose={props.handleClose}
      open
    >
      <StyledDialogTitle id="form-dialog-title">Log Details</StyledDialogTitle>
      <DialogContent>
      {(!props.isBacktest && inputJson && outputJson) && <ChartAction
          className="App-export"
          disableRipple
          onClick={downloadInputs}
          title="Click to export data for qrg"
          variant="contained"
        >
          Export for QRG
          <GetApp className="App-export-icon" />
        </ChartAction>}
        <div className="Row Details-headers">
          <div className="Details-column-1">
            <span>{props.log.http_route}</span>
          </div>
          <div className="Details-column-2">
            <span>{props.log.log_id}</span>
          </div>
        </div>
        <div className="Row Details-headers">
          <div className="Json-header">
            <span>Input Request</span>
            <IconButton
              aria-label="Copy"
              title="Copy to clipboard"
              onClick={() => navigator.clipboard.writeText(JSON.stringify(inputJson, null, 2))}
            >
              <ContentCopyIcon />
            </IconButton>
          </div>
          <div className="Json-header">
            <span>Output Response</span>
            <IconButton
              aria-label="Copy"
              title="Copy to clipboard"
              onClick={() => navigator.clipboard.writeText(JSON.stringify(outputJson, null, 2))}
            >
              <ContentCopyIcon />
            </IconButton>
          </div>
        </div>
        <div className="Row Json-values">
          <div className="Json">
            <JSONTree
              data={inputJson}
              getItemString={getItemString}
              hideRoot
              invertTheme={false}
              sortObjectKeys={(a, b) => (a as string).localeCompare(b as string)}
              theme={theme}
              shouldExpandNodeInitially={() => true}
            />
          </div>
          <div className="Json">
            <JSONTree
              data={outputJson}
              getItemString={getItemString}
              hideRoot
              invertTheme={false}
              sortObjectKeys={(a, b) => (a as string).localeCompare(b as string)}
              theme={theme}
              shouldExpandNodeInitially={() => true}
            />
          </div>
        </div>
      </DialogContent>
      <DialogButtons>
        <DialogButton autoFocus disableRipple onClick={props.handleClose}>
          Close
        </DialogButton>
      </DialogButtons>
    </Dialog>
  );
}
const ChartAction = styled(Button)`
  position: absolute;
  top: 15px;
  right: 40px;
  height: 36px;
  background-color: #ddd;
  color: black;

  &:hover {
    background-color: #00a76f;
    border-radius: 5px;
    color: white;
  }
`;

export default DetailsDialog;
