// ========================================================
// Default Configuration
// ========================================================

// ========================================================
// Environment Configuration
// ========================================================

import environments from './environments';

type Environment = 'development' | 'integration' | 'production' | 'test';

type Config = {
  env: Environment;
  AUTHENTICATOR_URL: string;
  MONITORING_URL: string;
  SMART_ALLOCATION_URL: string;
};

const env = (window.appVariables.APP_ENV as Environment) || 'development';

const config: Config = { env, ...environments[env] };

export default config;
