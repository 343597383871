import axios from 'axios';
import URI from 'urijs';

import { format, max, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DistribId, DistribIdBacktest, Log, User, isLegacyLog } from 'utils/types';
import config from './config';
import { formatDuration } from './dateUtils';

const minBeginDate = new Date('2018-02-26');

const getLogs = async (beginDate: Date, endDate: Date, distribId?: DistribId) => {
  let params: {
    begin_date: string;
    end_date: string;
    distrib_id?: string;
  } = {
    begin_date: format(max([beginDate, minBeginDate]), 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
  };
  let urlString = `${config.MONITORING_URL}/smart-allocation/logs/mfaaas_v2`;

  if (distribId !== undefined) {
    params = { ...params, distrib_id: distribId };
    urlString = `${config.MONITORING_URL}/smart-allocation/logs`;
  }

  const url = new URI(urlString);
  const response = await axios.get<Log[]>(url.toString(), {
    headers: getAuthorizationHeaders(),
    params,
  });

  const logs = response.data.filter(
    (log: Log) =>
      !(
        isLegacyLog(log) &&
        log.http_route === '/arbitrage' &&
        log.portfolio_amount === 50000 &&
        log.target_risk === 6
      ),
  );

  logs.map((log: Log) => {
    const formattedLog = log;

    formattedLog.date = zonedTimeToUtc(formattedLog.start_date, 'UTC');

    formattedLog.day = startOfDay(formattedLog.date);
    formattedLog.start_date = format(formattedLog.date, 'yyyy-MM-dd HH:mm:ss');

    formattedLog.readable_duration = formatDuration(formattedLog.duration_in_ms);

    if (isLegacyLog(formattedLog) && formattedLog.http_route === '/allocate-regular-investment') {
      formattedLog.risk_after_operation = formattedLog.risk_after_1_year;
    }

    if (formattedLog.http_status < 200) {
      formattedLog.status = 'Info';
    } else if (formattedLog.http_status < 300) {
      formattedLog.status = 'Success';
    } else if (formattedLog.http_status < 400) {
      formattedLog.status = 'Redirect';
    } else if (formattedLog.http_status < 500) {
      formattedLog.status = 'Client Error';
    } else {
      formattedLog.status = 'Server Error';
    }

    return formattedLog;
  });
  logs.sort((log1: Log, log2: Log) => -log1.start_date.localeCompare(log2.start_date));
  return logs;
};

const getLogsBacktest = async (beginDate: Date, endDate: Date, distribId: DistribIdBacktest) => {
  const params: {
    begin_date: string;
    end_date: string;
    distrib_id: string;
  } = {
    begin_date: format(max([beginDate, minBeginDate]), 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
    distrib_id: distribId,
  };
  const backtestUrl = new URI(`${config.MONITORING_URL}/backtest/logs`);
  const lookingForwardUrl = new URI(`${config.MONITORING_URL}/looking-forward/logs`);

  const [backtestResponse, lookingForwardResponse] = await Promise.all([
    axios.get<Log[]>(backtestUrl.toString(), {
      headers: getAuthorizationHeaders(),
      params,
    }),
    axios.get<Log[]>(lookingForwardUrl.toString(), {
      headers: getAuthorizationHeaders(),
      params,
    }),
  ]);

  let logs = [...backtestResponse.data, ...lookingForwardResponse.data];

  logs = logs.map((log: Log) => {
    const formattedLog = log;

    formattedLog.date = zonedTimeToUtc(formattedLog.start_date, 'UTC');

    formattedLog.day = startOfDay(formattedLog.date);
    formattedLog.start_date = format(formattedLog.date, 'yyyy-MM-dd HH:mm:ss');

    formattedLog.readable_duration = formatDuration(formattedLog.duration_in_ms);

    if (formattedLog.http_status < 200) {
      formattedLog.status = 'Info';
    } else if (formattedLog.http_status < 300) {
      formattedLog.status = 'Success';
    } else if (formattedLog.http_status < 400) {
      formattedLog.status = 'Redirect';
    } else if (formattedLog.http_status < 500) {
      formattedLog.status = 'Client Error';
    } else {
      formattedLog.status = 'Server Error';
    }

    return formattedLog;
  });
  logs.sort((log1: Log, log2: Log) => -log1.start_date.localeCompare(log2.start_date));

  return logs;
};

const getLogsExport = async (beginDate: Date, endDate: Date, distribId?: DistribId) => {
  let params: { begin_date: string; end_date: string; distrib_id?: string } = {
    begin_date: format(max([beginDate, minBeginDate]), 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
  };
  let urlString = `${config.MONITORING_URL}/smart-allocation/logs_export/mfaaas_v2`;

  if (distribId !== undefined) {
    params = { ...params, distrib_id: distribId };
    urlString = `${config.MONITORING_URL}/smart-allocation/logs_export`;
  }

  const url = new URI(urlString);
  const response = await axios.get(url.toString(), {
    headers: getAuthorizationHeaders(),
    responseType: 'blob',
    params,
  });

  const fileUrl = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = `Export-logs-${Date.now()}.zip`;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(fileUrl);
};

const getLogsExportBacktest = async (
  beginDate: Date,
  endDate: Date,
  distribId: DistribIdBacktest,
) => {
  const params: { begin_date: string; end_date: string; distrib_id?: string } = {
    begin_date: format(max([beginDate, minBeginDate]), 'yyyy-MM-dd'),
    end_date: format(endDate, 'yyyy-MM-dd'),
    distrib_id: distribId,
  };
  const backtestUrl = new URI(`${config.MONITORING_URL}/backtest/logs-export`);
  const lookingForwardUrl = new URI(`${config.MONITORING_URL}/looking-forward/logs-export`);

  const [backtestResponse, lookingForwardResponse] = await Promise.all([
    axios.get(backtestUrl.toString(), {
      headers: getAuthorizationHeaders(),
      responseType: 'blob',
      params,
    }),
    axios.get(lookingForwardUrl.toString(), {
      headers: getAuthorizationHeaders(),
      responseType: 'blob',
      params,
    }),
  ]);

  const responses = [backtestResponse, lookingForwardResponse];
  const currentDate = Date.now();
  const fileNames = [
    `Export-logs-backtest-${currentDate}.zip`,
    `Export-logs-looking-forward-${currentDate}.zip`,
  ];
  const link = document.createElement('a');

  for (let i = 0; i < 2; i += 1) {
    const fileUrl = window.URL.createObjectURL(responses[i].data);
    link.href = fileUrl;
    link.download = fileNames[i];
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    document.body.removeChild(link);
  }
};

const getQrgInputs = async (log: object, route: string) => {
  const body = { ...log, export_data_for_qrg: true };

  const url = new URI(`${config.SMART_ALLOCATION_URL}${route}`);

  const response = await axios.post(url.toString(), body, {
    headers: {
      ...getAuthorizationHeaders(),
    },
    responseType: 'blob',
  });

  const fileUrl = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = `data.zip`;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(fileUrl);
};

const getLog = async (id: string, isBacktest: boolean = false) => {
  const baseRoute: string = isBacktest ? 'backtest-looking-forward' : 'smart-allocation';
  const response = await axios.get(`${config.MONITORING_URL}/${baseRoute}/log/raw-content/${id}`, {
    headers: getAuthorizationHeaders(),
  });
  return response.data;
};

const signIn = async (email: string, password: string, game: string) => {
  const response = await axios.post(`${config.AUTHENTICATOR_URL}/login`, {
    email,
    game,
    password,
  });

  return response.data;
};

const whoAmI = async () => {
  const res = await axios.get<User>(`${config.AUTHENTICATOR_URL}/me`, {
    headers: getAuthorizationHeaders(),
  });
  return res.data;
};

const getAuthorizationHeaders = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return {
      Authorization: `token ${token}`,
    };
  }

  return {};
};

export {
  getLog,
  getLogs,
  getLogsBacktest,
  getLogsExport,
  getLogsExportBacktest,
  signIn,
  whoAmI,
  getQrgInputs,
};
