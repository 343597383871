import { useState } from 'react';

import AccountCircle from '@mui/icons-material/AccountCircle';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import {
  AppBar,
  Button,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';

import { DistribId, DistribIdBacktest, Cf, User } from 'utils/types';
import { formatCount } from '../../utils/numbersUtils';

import './MainBar.css';

interface IMainBarProps {
  cf: Cf | null;
  changeDistribIds: (event: SelectChangeEvent<DistribId>) => void;
  changeDistribIdsBacktest: (event: SelectChangeEvent<DistribIdBacktest>) => void;
  isTeamExcluded: boolean;
  handleSignOut: () => void;
  signedIn: boolean;
  toggleExcludeTeam: () => void;
  user: User | null;
  workers: number;
  distribId: DistribId | undefined;
  distribIdBacktest: DistribIdBacktest | undefined;
  withRebalancingCount: boolean;
}

function MainBar({ ...props }: IMainBarProps) {
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const handleCloseAccountMenu = () => {
    setAccountMenuOpen(false);
  };

  const handleOpenAccountMenu = () => {
    setAccountMenuOpen(true);
  };

  const handleChangeDistrib = (event: SelectChangeEvent<DistribId>) => {
    props.changeDistribIds(event);
  };

  const handleChangeDistribBacktest = (event: SelectChangeEvent<DistribIdBacktest>) => {
    props.changeDistribIdsBacktest(event);
  };

  return (
    <AppBar color="primary">
      <Toolbar>
        <Typography color="inherit">Smart Logs</Typography>
        {props.workers === 0 && (
          <>
            <Select
              onChange={handleChangeDistrib}
              className="distribIds"
              value={props.distribId}
              placeholder="Smart-Allocation"
              label="Smart-Allocation"
              displayEmpty
              variant="standard"
              sx={{
                marginLeft: '10%',
              }}
            >
              <MenuItem value={undefined} disabled>
                Smart-Allocation
              </MenuItem>
              <MenuItem value="bddf">BDDF & HB FR (Smart-Allocation)</MenuItem>
              <MenuItem value="bpf">BPF (Smart-Allocation)</MenuItem>
              <MenuItem value="mfaaas">MFAAAS (Smart-Allocation)</MenuItem>
              <MenuItem value="mfaaas_v2">MFAAAS V2 (Smart-Allocation)</MenuItem>
              <MenuItem value="digital-clubs">Digital Clubs (Smart-Allocation)</MenuItem>
              <MenuItem value="hb_be">HB BE (Smart-Allocation)</MenuItem>
            </Select>
  
            <Select
              onChange={handleChangeDistribBacktest}
              className="distribIdsBacktest"
              value={props.distribIdBacktest}
              placeholder="Backtest"
              label="Backtest"
              displayEmpty
              variant="standard"
              sx={{
                marginLeft: '10%',
              }}
            >
              <MenuItem value={undefined} disabled>
                Backtest
              </MenuItem>
              <MenuItem value="bddf">BDDF (Backtest)</MenuItem>
              <MenuItem value="bnpp_am">BNPP AM (Backtest)</MenuItem>
              <MenuItem value="bpf">BPF (Backtest)</MenuItem>
              <MenuItem value="digital-clubs">Digital Clubs (Backtest)</MenuItem>
              <MenuItem value="hb_be">HB BE (Backtest)</MenuItem>
              <MenuItem value="hb_fr">HB FR (Backtest)</MenuItem>
              <MenuItem value="bcef">BCEF (Backtest)</MenuItem>
              <MenuItem value="bnl">BNL (Backtest)</MenuItem>
            </Select>
          </>
        )}
        <div className="App-counts">
          {props.cf && (
            <AppCount className="App-count">
              <strong>
                {formatCount(props.cf.userGroup.all().filter((d) => d.value !== 0).length)}
              </strong>
              &nbsp; user(s)
            </AppCount>
          )}
          {props.cf && (
            <AppCount className="App-count">
              <strong>{formatCount(props.cf.all.value())}</strong>
              &nbsp; log(s)
            </AppCount>
          )}
          {props.cf && props.withRebalancingCount && (
            <AppCount className="App-count">
              <strong>
                {formatCount(
                  (props.cf.rebalancingGroup.all().find((d) => d.key === true)?.value as number) ??
                    0,
                )}
              </strong>
              &nbsp; log(s) during rebalancing
            </AppCount>
          )}
        </div>
        {props.cf && (
          <FormControlLabel
            control={
              <Switch
                checked={props.isTeamExcluded}
                onChange={props.toggleExcludeTeam}
                value="isTeamExcluded"
                color="secondary"
              />
            }
            label="Exclude SA team"
          />
        )}
        <CircularProgress
          color="inherit"
          style={{
            visibility: props.workers > 0 ? 'visible' : 'hidden',
          }}
        />
        {props.signedIn && (
          <div onMouseEnter={handleOpenAccountMenu} onMouseLeave={handleCloseAccountMenu}>
            <AppMenu color="inherit" className="App-menu" disableFocusRipple disableRipple>
              <AccountCircle />
              {props.user?.profile.firstname}
              &nbsp;&nbsp;
              {props.user?.profile.lastname}
            </AppMenu>
            {accountMenuOpen && (
              <AppAccountmenu className="App-account-menu" dense>
                <ListItem>
                  <AppMenuButton
                    className="App-menu-button"
                    disableFocusRipple
                    disableRipple
                    onClick={props.handleSignOut}
                    size="large"
                  >
                    <PowerSettingsNew color="inherit" />
                    Sign Out
                  </AppMenuButton>
                </ListItem>
              </AppAccountmenu>
            )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

const AppAccountmenu = styled(List)`
  background-color: white;
  border-radius: 3px;
  padding: 0;
  position: absolute;
  right: 24px;
  top: 50px;
  width: 200px;

  & > li {
    padding: 3px;
  }

  & > ul {
    padding: 0;
  }
`;

const AppMenuButton = styled(Button)`
  border-radius: 3px;
  width: 100%;
  color: black;
  justify-content: flex-start;

  &:hover {
    background-color: #00a76f;
    color: white;
  }

  & > svg {
    margin-right: 8px;
  }
`;

const AppMenu = styled(Button)`
  width: 200px;
  &:hover {
    border-radius: 3px;
  }

  & > svg {
    margin-right: 8px;
  }
`;

const AppCount = styled(Typography)`
  background-color: #00a76f;
  border-radius: 10px;
  color: white;
  margin-right: 12px;
  padding: 3px 10px;
  text-align: center;
`;

export default MainBar;
