import { Charts } from '../types';

export const StandardCharts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'error_type',
    defaultDimensionName: 'No Error',
    title: 'Error Type',
    width: 275,
  },
  {
    name: 'rowChart',
    logKey: 'endpoint',
    title: 'Route',
    height: 275,
    withLabel: true,
  },
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Status',
    width: 275,
  },
];

export const BDDFBacktestCharts: Charts[] = StandardCharts;

export const BNPPAMBacktestCharts: Charts[] = StandardCharts;

export const BPFBacktestCharts: Charts[] = StandardCharts;

export const DigitalClubsBacktestCharts: Charts[] = StandardCharts;

export const HBBEBacktestCharts: Charts[] = StandardCharts;

export const HBFRBacktestCharts: Charts[] = StandardCharts;

export const BCEFBacktestCharts: Charts[] = StandardCharts;

export const BNLBacktestCharts: Charts[] = StandardCharts;
