import { DistribId, DistribIdBacktest } from 'utils/types';

export const PERIODICITY_TO_MULTIPLICATOR_AMOUNT_LOOKUP = {
  monthly: 12,
  quarterly: 4,
  biannual: 2,
  annual: 1,
};

export const logColumnsMapping: { [key: string]: { [distribId in DistribId]: boolean } } = {
  startDate: {
    bddf: true,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': true,
    hb_be: true,
  },
  httpRoute: {
    bddf: true,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': true,
    hb_be: true,
  },
  httpStatus: {
    bddf: true,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': true,
    hb_be: true,
  },
  duration: {
    bddf: true,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': true,
    hb_be: true,
  },
  portfolioAmount: {
    bddf: true,
    bpf: true,
    mfaaas: false,
    mfaaas_v2: false,
    'digital-clubs': true,
    hb_be: true,
  },
  investmentAmount: {
    bddf: true,
    bpf: true,
    mfaaas: false,
    mfaaas_v2: false,
    'digital-clubs': true,
    hb_be: true,
  },
  initialVolatility: {
    bddf: false,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': false,
    hb_be: true,
  },
  initialRISK: {
    bddf: true,
    bpf: false,
    mfaaas: false,
    mfaaas_v2: false,
    'digital-clubs': true,
    hb_be: false,
  },
  finalVolatility: {
    bddf: false,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': false,
    hb_be: true,
  },
  finalRISK: {
    bddf: true,
    bpf: false,
    mfaaas: false,
    mfaaas_v2: false,
    'digital-clubs': true,
    hb_be: false,
  },
  targetVolatility: {
    bddf: false,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': false,
    hb_be: true,
  },
  targetRISK: {
    bddf: true,
    bpf: false,
    mfaaas: false,
    mfaaas_v2: false,
    'digital-clubs': true,
    hb_be: false,
  },
  maxVolatility: {
    bddf: false,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': false,
    hb_be: false,
  },
  errorType: {
    bddf: true,
    bpf: true,
    mfaaas: true,
    mfaaas_v2: true,
    'digital-clubs': true,
    hb_be: true,
  },
} as const;

export const logColumnsMappingBacktest: {
  [key: string]: { [distribId in DistribIdBacktest]: boolean };
} = {
  startDate: {
    bddf: true,
    bnpp_am: true,
    bpf: true,
    'digital-clubs': true,
    hb_be: true,
    hb_fr: true,
    bcef: true,
    bnl: true,
  },
  httpRoute: {
    bddf: true,
    bnpp_am: true,
    bpf: true,
    'digital-clubs': true,
    hb_be: true,
    hb_fr: true,
    bcef: true,
    bnl: true,
  },
  httpStatus: {
    bddf: true,
    bnpp_am: true,
    bpf: true,
    'digital-clubs': true,
    hb_be: true,
    hb_fr: true,
    bcef: true,
    bnl: true,
  },
  duration: {
    bddf: true,
    bnpp_am: true,
    bpf: true,
    'digital-clubs': true,
    hb_be: true,
    hb_fr: true,
    bcef: true,
    bnl: true,
  },
  errorType: {
    bddf: true,
    bnpp_am: true,
    bpf: true,
    'digital-clubs': true,
    hb_be: true,
    hb_fr: true,
    bcef: true,
    bnl: true,
  },
} as const;

export const weightTolerance = 0.01;
